import { css } from '@emotion/react'
import { cssColor, cssText, mfThemeVar } from 'fe-style'

export const tooltipText = css`
  label: tool-tip-text;
  margin: 0px;

  ::before {
    content: '';
    display: block;
    position: absolute;
    left: 190px;
    top: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: ${cssColor.PRIMARY};
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 191px;
    top: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-top-color: ${cssColor.NEUTRAL_0};
  }
`
export const tooltipTextISO = css`
  label: tool-tip-text-iso;
  color: #0e4455;

  font-family: ${cssText.fontFamily.MONTSERRAT_SANS_SERIF};
  ${cssText.fontSize._14px};
  ${cssText.fontWeight._SEMI_BOLD};
  ${cssText.letterSpacing._pos0p3}
  ${cssText.lineHeight._20px}
`

export const tooltipPercent = css`
  label: tool-tip-percent;
  color: var(${mfThemeVar.PRIMARY_DARK});
  font-family: ${cssText.fontFamily.MONTSERRAT_SANS_SERIF};
  ${cssText.fontSize._16px};
  ${cssText.fontWeight._SEMI_BOLD};
  ${cssText.letterSpacing._pos0p3}
  line-height: 19px;
  padding-right: 10px;
  padding-right: 5px;
`
