import { PaymentFrequency } from 'api-partner-portal'
import { Calculator } from '../models'

export const DEFAULT_CALCULATOR: Calculator = {
  amount: 100_000,
  term: 18,
  commission: 5,
  originationFee: 1.5,
  frequency: PaymentFrequency.weekly,
  netFundingAmount: 95_000,
}
