import { css } from '@emotion/react'
import { mfThemeVar, style } from 'fe-style'

export const app = css`
  label: app;
  height: 100vh;
  width: 100vw;
`

export const background = css`
  label: background;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
`

export const global = css`
  label: global;
  body {
    margin: 0;
    background-color: var(${mfThemeVar.NEUTRAL_15});
    font-family: Montserrat;
    input {
      font-family: Montserrat;
    }
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
    }

    --slider-blue: #3e6977;
    --slider-grey: #cbcbcb;

    /* set standard theme css variables */
    ${style.cssTheme._setMfStandard}
  }
`
export const page = css`
  flex: 1;
`
