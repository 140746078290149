import { css } from '@emotion/react'
import { cssColor, cssText } from 'fe-style'

export const info = css`
  label: info;
  border-radius: 4px;
  background-color: ${cssColor.INFO_LIGHT};
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 10px;

  div {
    color: ${cssColor.TERTIARY};
    font-family: ${cssText.fontFamily.MONTSERRAT_SANS_SERIF}
    ${cssText.fontSize._14px}
    ${cssText.letterSpacing._pos0p3}
    ${cssText.lineHeight._24px}
    padding-left: 10px;
  }
`

export const infoIcon = css`
  label: info-icon;
  position: relative;
  top: 5px;
  height: 18.33px;
  width: 18.33px;
  fill: ${cssColor.TERTIARY};
`
