import { css } from '@emotion/react'
import { cssColor, mfThemeVar } from 'fe-style'
import { fontSize16 } from '../../constants'
export const pageWrapper = css`
  label: page-wrapper;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(${cssColor.NEUTRAL_15});
`

export const footer = css`
  label: footer;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 40px;
  color: var(${mfThemeVar.TEXT_LIGHT});
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 18px;
  font-weight: 700;
`
export const footerText = css`
  label: footer-text;
  color: var(${mfThemeVar.TEXT_LIGHT});
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 20px;
`
export const headerBar = css`
  label: header-bar;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  align-items: center;
  justify-content: center;
  height: 72px;
  border-bottom: 1px solid var(${mfThemeVar.NEUTRAL_50});
  margin-bottom: 40px;
`

export const headerCentered = css`
  label: header-centered;
  display: flex;
  flex-direction: row;
  width: 1140px;
  align-items: center;
`

export const headerDivider = css`
  label: header-divider;
  background-color: var(${mfThemeVar.NEUTRAL_50});
  width: 1px;
  height: 36px;
  margin: 0 15px;
`

export const headerIcon = css`
  label: header-icon;
  height: 32px;
  width: 265px;
`
export const page = css`
  label: page;
  box-sizing: border-box;
  background-color: var(--background-light-grey);
  margin: 0 auto;
`
export const title = css`
  label: title;
  color: var(${mfThemeVar.TEXT_DEFAULT});
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.3px;
`
export const body = css`
  label: body;
  min-height: 400px;
  width: 100%;
  color: #67696a;
  ${fontSize16}
`
