import { css } from '@emotion/react'
import { fontSize16, paddedBlock } from '../../constants'
import patternBackgroundDark from '../../assets/imgs/pattern-background-dark.png'
import { cssColor, cssText, mfThemeVar } from 'fe-style'

export const adBannerRow = css`
  label: ad-banner;
  margin-top: -20px;
  margin-bottom: 13px;
  text-align: center;
`

export const arrowIcon = css`
  width: 16px;
  height: 9px;
`
export const arrowIconWrapper = css`
  display: flex;
  width: 16px;
  height: 100%;
  align-items: center;
`
export const block = css`
  background-color: var(${mfThemeVar.NEUTRAL_15});
  flex: 1;
  display: flex;
  flex-direction: row;
`

const buttonStyles = css`
  label: button-styles;
  box-sizing: border-box;
  height: 40px;
  width: 95px;
  color: ${cssColor.NEUTRAL_0};
  ${cssText.fontSize._18px};
  ${cssText.fontWeight._SEMI_BOLD},
  ${cssText.letterSpacing._pos0p3};
  ${cssText.lineHeight._24px};

  text-align: right;
  border: 1px solid ${cssColor.TERTIARY};
  background-color: ${cssColor.TERTIARY};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const epsBlock = css`
  display: flex;
  flex-direction: row;
  margin-top: 4 px;
  cursor: pointer;
`

export const epsText = css`
  color: ${cssColor.LINK};
  ${cssText.fontSize._16px};
  ${cssText.fontWeight._MEDIUM},
  ${cssText.letterSpacing._pos0p3};
  ${cssText.lineHeight._24px};
  margin-left: 6px;
`

export const horizontalSeparator = css`
  border-top: 1px solid ${cssColor.NEUTRAL_15};
  margin: 20px 0 20px 0;
  opacity: 0.24;
`
export const infoIcon = css`
  position: relative;
  top: 2px;
`

export const circleArrowIcon = css`
  position: relative;
  top: 6px;
  padding-right: 10px;
`

export const leftBlock = css`
  box-sizing: border-box;
  flex: 3;
  margin-right: 22px;
`
export const leftButton = css`
  ${buttonStyles},
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`
export const lineBlock = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 6px;
`
export const lineLabel = css`
  flex: 1;
  color: ${cssColor.NEUTRAL_0};
  ${cssText.fontSize._16px};
  ${cssText.fontWeight._SEMI_BOLD},
  ${cssText.letterSpacing._pos0p3};
  ${cssText.lineHeight._24px};
`

export const lineValue = css`
  label: line-value;
  flex: 1;
  color: ${cssColor.NEUTRAL_0};
  ${cssText.fontSize._20px};
  ${cssText.fontWeight._SEMI_BOLD},
  ${cssText.letterSpacing._pos0p3};
  ${cssText.lineHeight._24px};
  text-align: right;
`

export const repaymentAmount = css`
  label: repayment-amount;
  color: var(${mfThemeVar.TEXT_DEFAULT});
  font-size: 44px;
  ${cssText.fontWeight._SEMI_BOLD},
  ${cssText.letterSpacing._pos0p3};
  line-height: 61px;
  text-align: right;
`
export const repaymentBlock = css`
  label: repayment-block;
  border-radius: 6px;
  background-color: ${cssColor.NEUTRAL_0};
  margin: 10px 0 20px 0;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const repaymentTitle = css`
  color: var(${mfThemeVar.TEXT_LIGHT});
  ${cssText.fontSize._14px};
  ${cssText.fontWeight._BOLD},
  ${cssText.letterSpacing._pos1p25};
  ${cssText.lineHeight._24px};
  text-align: center;
`
export const rightBlock = css({
  flex: 2,
})

export const rightBlockContents = css`
  label: rightBlockContents
  ${paddedBlock}
  color: ${cssColor.NEUTRAL_0};
  background-color: var(${mfThemeVar.TERTIARY});
  background-image: url(${patternBackgroundDark});
  border-radius: 4px;
  width: 458px;
`

export const rightButton = css`
  ${buttonStyles}
  border-radius: 0 4px 4px 0;
`

export const rtfAdditionalSection = css``

export const rtfAdditionalStatement = css`
  color:  var(${mfThemeVar.TERTIARY});
  ${fontSize16}
  margin: 30px 0 16px 0;
}
`
export const rtfBlock = css`
  box-sizing: border-box;
  padding: 8px 16px;
  width: 100%;
  border-radius: 4px;
  background-color: var(${mfThemeVar.NEUTRAL_15});
  margin-top: 5px;
`

export const rtfContents = css`
  margin-top: 16px;
`
export const rtfTitleRow = css`
  display: flex;
  flex-direction: row;
  height: 24px;
`

export const rtfTitle = css`
  flex: 1;
  color: var(${mfThemeVar.TERTIARY});
  ${cssText.fontSize._16px};
  ${cssText.fontWeight._SEMI_BOLD},
  ${cssText.letterSpacing._pos0p3};
  ${cssText.lineHeight._24px};
`

export const bullet = css`
  label: req-to-fund-bullet;
  position: relative;
  bottom: 1px;
`

export const reqToFundReqline = css`
  label: req-to-fund-req-line;
  margin-left: 2em;
  text-indent: -2em;
  margin-bottom: 4px;
`

export const reqNote1 = css(
  'label: req-note-1;',
  'margin-top: 11px;',
  'font-style: italic;',
)

export const sectionHeading = css`
  color: ${cssColor.NEUTRAL_0};
  ${cssText.fontSize._20px};
  ${cssText.fontWeight._SEMI_BOLD},
  ${cssText.letterSpacing._neg0p3};
  ${cssText.lineHeight._30px};
  text-align: center;
`
export const submitButton = css`
  margin: 20px 0;
`
