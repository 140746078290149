import { css } from '@emotion/react'
import { cssText, mfThemeVar } from 'fe-style'
import { logColor } from './log-color'

export const defFont = (
  weight: 'b' | 'm' | 'r' | 'sb',
  sizePx: 14 | 16 | 18 | 20 | 24 | 28 | 32 | 36 | 40 | 44,
  lineHeightPx?: 20 | 24 | 26 | 28 | 30 | 34 | 38 | 42 | 46 | 50 | 54,
  letterSpacing?: -0.3 | 0.3 | 1.25,
  colorVar?: mfThemeVar,
) => {
  let keyLetterSpacing
  if (letterSpacing === -0.3) {
    keyLetterSpacing = '_neg0p3'
  } else if (letterSpacing === 0.3) {
    keyLetterSpacing = '_pos0p3'
  } else if (letterSpacing === 1.25) {
    keyLetterSpacing = '_pos1p25'
  } else {
    if (letterSpacing) {
      console.log(
        'invalid letter spacing for defFont, defaulting to 0.3: ' +
          letterSpacing,
      )
    }
    keyLetterSpacing = '_pos0p3'
  }
  let keyWeight
  if (weight === 'b') {
    keyWeight = '_BOLD'
  } else if (weight === 'm') {
    keyWeight = '_MEDIUM'
  } else if (weight === 'r') {
    keyWeight = '_REGULAR'
  } else if (weight === 'sb') {
    keyWeight = '_SEMI_BOLD'
  } else {
    logColor.yellow('invalid weight: ' + weight)
  }
  return css`
    font-family: var(${mfThemeVar.FONT_FAMILY});
    ${cssText.fontWeight[keyWeight]}
    font-size: ${sizePx}px;
    ${letterSpacing ? cssText.letterSpacing[keyLetterSpacing] : ''}
    ${lineHeightPx ? `line-height: ${lineHeightPx}px;` : ''}
    ${colorVar ? `color: var(${colorVar});` : ''}
  `
}
