import { css } from '@emotion/react'
import { cssColor, cssText, mfThemeVar } from 'fe-style'

export const body = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(${mfThemeVar.FONT_FAMILY});
  color: var(${mfThemeVar.TEXT_DEFAULT});
`

export const title = css`
  margin: 22px 0 32px 0;
  ${cssText.fontSize._24px}
  ${cssText.fontWeight._MEDIUM}
    ${cssText.letterSpacing._neg0p3}
`

export const text = css`
  ${cssText.fontSize._16px}
  ${cssText.fontWeight._REGULAR}
    ${cssText.letterSpacing._pos0p3}
    text-align: center;
  margin: 0 55px 35px 55px;
`

export const cancelLink = css`
  margin-bottom: 65px;
  ${cssText.fontSize._16px}
  ${cssText.letterSpacing._pos0p3}
    color: var(${mfThemeVar.LINK});
  cursor: pointer;
  margin-top: 30px;
`
