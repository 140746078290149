import { css } from '@emotion/react'
import { cssText } from 'fe-style'
import radioButtonDefaultIcon from './../../assets/imgs/radio-button-default-icon.svg'
import radioButtonSelectedIcon from './../../assets/imgs/radio-button-selected-icon.svg'

export const sectionBlock = css`
  label: section-block;
  flex: 3;
  border-radius: 4px;
  background-color: #f3f6f7;
  margin: 2px;
  margin-bottom: 10px;
  height: 65px;
  padding-top: 10px;
  padding-left: 15px;
`
export const radioBlock = css`
  label: radio-block;
  color: var(--shade-blue);

  height: 24px;
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
`
export const radioGroup = css`
  label: radio-group;
  display: flex;
  flex-direction: row;
`

export const title = css`
  label: title;
  height: 20px;
  color: #0e4455;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 20px;
  padding-bottom: 5px;
`
export const value = css`
  display: inline;
  color: #0e4455;
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
`

export const radioOption = css`
  label: radio-option;
  margin-right: 20px;
  color: #0e4455;
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
`

export const radioButton = css`
  label: radio-button;
  margin: 5px;
  margin-left: 0px;
  margin-left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border-width: 0;
  vertical-align: middle;
  :after {
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    background: url(${radioButtonDefaultIcon}) center no-repeat scroll;
  }
  :checked:after {
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    position: relative;
    display: inline-block;
    visibility: visible;
    background: url(${radioButtonSelectedIcon}) center no-repeat scroll;
  }
`
