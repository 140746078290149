import { css } from '@emotion/react'
import { mfThemeVar } from 'fe-style'
import { defFont } from './../../utils'

export const displayValue = defFont('r', 16, null, 0.3, mfThemeVar.TEXT_LIGHT)
const _heavyText16 = defFont('sb', 16, 24, 0.3, mfThemeVar.TEXT_DEFAULT).styles

export const displayBox = css`
  background-color: var(${mfThemeVar.NEUTRAL_15});
  padding: 10px;
  box-sizing: border-box;
`

export const displayBoldBox = css`
  label: iso-contact-email;
  ${_heavyText16}
  text-align:center;
  width: 100%;
`
export const inputErrorWrapper = css`
  label: input-error-wrapper;
  position: relative;
`

export const textInput = css(
  `
        label: text-input;
        border: 1px solid var(${mfThemeVar.NEUTRAL_60});
        height: 40px;
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
      `,
  displayBox,
  displayValue,
)

export const textInputError = css`
  ${textInput}
  label: error;
  border-color: var(${mfThemeVar.ERROR});
`

export const inputErrorMessage = css(
  'label: input-error-message;',
  defFont('r', 14, 20, 0.3, mfThemeVar.ERROR),
  `position: absolute;`,
)

export const hideMe = css`
  display: none;
`
