import * as React from 'react'
import * as styles from './merchant-card.styles'
import { CheckboxButton } from '../checkbox-button'
import { PaymentFrequency, PricingTier } from 'api-partner-portal'
import { RadioButtonGroup } from '../radio-button-group'

export interface MerchantCardProps {
  loanSpecialist: string
  merchantName: string
  tier: PricingTier
  interestForgiveness: string
  tierList: Array<string>
  interestForgivenessList: Array<string>
  paymentFrequency?: string
  paymentFrequencyOptions: PaymentFrequency[]
  handleSelectedTierChange: (newValue: string) => void
  handleInterestForgivenessChange: (newValue: string) => void
  handlePaymentFrequencyChange: (newValue: string) => void
}
export const MerchantCard: React.FC<MerchantCardProps> = (props) => {
  const {
    loanSpecialist,
    merchantName,
    tier,
    interestForgiveness,
    tierList,
    interestForgivenessList,
    handleSelectedTierChange,
    handleInterestForgivenessChange,
    handlePaymentFrequencyChange,
    paymentFrequency,
    paymentFrequencyOptions,
  } = props

  return (
    <div css={styles.card}>
      <div css={styles.merchantBlock}>
        <div css={styles.lineMarginBottom}>
          <div css={styles.merchantLabel}>{`Merchant:`}</div>
          <div css={styles.merchantValue}>{merchantName}</div>
        </div>
        {tierList.length > 1 &&
        <div css={styles.lineMarginBottom}>
          <div css={styles.merchantLabel}>{`Tier:`}</div>
          <div css={styles.merchantTierBlock}>
            {tierList.map((tierName) => {
              return (
                <CheckboxButton
                  key={tierName}
                  handleChange={handleSelectedTierChange}
                  isSelected={tier?.tier_basic_name
                    ?.toLocaleLowerCase()
                    .includes(tierName)}
                  id={tierName}
                  label={tierName}
                  name={'selectedTier'}
                  isLocked={tierList.length == 1}
                />
              )
            })}
          </div>
        </div>}

        <div css={styles.line}>
          <RadioButtonGroup
            title={'Interest Forgiveness'}
            value={interestForgiveness}
            name={'interestForgiveness'}
            optionsList={interestForgivenessList}
            handleOnChange={handleInterestForgivenessChange}
            symbol={'%'}
          />
          <RadioButtonGroup
            title={'Payment Frequency'}
            value={paymentFrequency}
            name={'paymentFrequency'}
            optionsList={paymentFrequencyOptions}
            handleOnChange={handlePaymentFrequencyChange}
          />
        </div>
      </div>
      <div css={styles.specialistBlock}>
        <div css={styles.line}>
          <div css={styles.merchantLabel}>{`Loan Specialist:`}</div>
          <div css={styles.specialistDetails}>
            <div css={styles.specialistContact}>
              <div css={styles.specialistName}>{loanSpecialist}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
