import { css } from '@emotion/react'
import { mfThemeVar } from 'fe-style'
import { fontSize16 } from '../../constants'

export const block = css`
  label: block;
  display: flex;
  flex-direction: row;
`

export const checkIcon = css`
  label: check-icon;
  height: 12px;
  width: 16px;
`

export const checkIconWrapper = css`
  label: check-icon-wrapper;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  width: 16px;
  height: 100%;
  align-items: center;
  margin-right: 10px;
`

export const text = css`
  label: text;
  color: var(${mfThemeVar.TEXT_DEFAULT});
  font-size: 16px;
  letter-spacing: 0.3px;
`
