import { css } from '@emotion/react'
import { mfThemeVar } from 'fe-style'
import { defFont } from './../../utils'

export const body = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(${mfThemeVar.FONT_FAMILY});
  color: var(${mfThemeVar.TEXT_DEFAULT});
  padding-bottom: 50px;
`

export const title = css(
  `
    label: error-modal-title;
    margin: 22px 0 32px 0;
  `,
  defFont('m', 24, null, -0.3, mfThemeVar.TEXT_DEFAULT),
)

export const text = css(
  `
  label: error-modal-text;
  text-align: center;
  margin: 0 55px 35px 55px;
`,
  defFont('r', 16, null, 0.3, mfThemeVar.TEXT_DEFAULT),
)
