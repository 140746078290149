import { css } from '@emotion/react'
import { cssText, cssTyp, mfThemeVar } from 'fe-style'
import radioButtonDefaultIcon from './../../assets/imgs/radio-button-default-icon.svg'
import radioButtonSelectedIcon from './../../assets/imgs/radio-button-selected-icon.svg'

export const card = css`
  label: card;
  padding: 16px 32px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 13px 14px 0 rgba(19, 72, 89, 0.04);
  margin-top: 0;
`
export const merchantBlock = css`
  label: merchant-block;
`
export const merchantLabel = css`
  label: merchant-label;
  flex: 2;
  color: var(${mfThemeVar.TEXT_DEFAULT});
  ${cssTyp._H4_SMALL}
`
export const lineCss = `
  display: flex;
  flex-direction: row;
`

export const line = css`
  label: line;
  ${lineCss}
`

export const lineMarginBottom = css`
  label: line-margin-bottom;
  ${lineCss}
  margin-bottom: 10px;
`

export const merchantTierBlock = css`
  label: merchant-tier-block;
  display: flex;
  flex-direction: row;
`
export const merchantValue = css`
  label: merchant-value;
  flex: 3;
  color: var(${mfThemeVar.TEXT_DEFAULT});
  ${cssText.fontSize._20px};
  ${cssText.fontWeight._REGULAR};
  ${cssText.letterSpacing._pos0p3};
  ${cssText.lineHeight._28px};
  text-align: right;
`
export const specialistBlock = css`
  label: specialist-block;
  padding-top: 10px;
  border-top: 2px solid #e7ecee;
`
export const specialistContact = css`
  label: specialist-contact;
  display: flex;
  flex-direction: column;
`
export const specialistDetails = css`
  label: specialist-details;
  display: flex;
  flex-direction: row;
  align-items: right;
`

export const specialistName = css`
  label: specialist-name;
  color: var(${mfThemeVar.TEXT_DEFAULT});
  ${cssTyp._BODY_LARGE}
  text-align: right;
`
