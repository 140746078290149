import { partnerPortalApi, PaymentFrequency } from 'api-partner-portal'
import { DisplayPageStatus, PendingOfferTemp } from './../../constants'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  acceptedOfferPostState,
  calcPaymentFrequencyState,
  commissionRateState,
  displayPageState,
  errorMessageState,
  isPageLoadingState,
  loadSavedCalcState,
  loanAmountState,
  loanPaymentFrequencyOptionsState,
  loanSpecialistState,
  merchantNameState,
  monthMaxState,
  offerTypeState,
  pendingOfferState,
  requirementListState,
  selectedTierState,
  tierListState,
} from './../../state'
import { getPendingOfferErrors, hasOwn, logColor } from './../../utils'
import { Card, MessagePage, MessagePageType, Page } from './../../components'
import {
  CreateLoanAgreement,
  IsoCalculatorPage,
  OfferSelectedPage,
} from './../../routes'

export const Loading: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Page title={'Offer Submission Calculator'}>
      <Card width="1140px">{children || 'Loading...'}</Card>
    </Page>
  )
}

// LOADER
export const Loader: React.FC = () => {
  const { calcUuid } = useParams()
  const [displayComponent, setDisplayComponent] = useState(<Loading />)

  const [errorMessage, setErrorMessage] =
    useRecoilState<string>(errorMessageState)
  const [displayPage, setDisplayPage] = useRecoilState(displayPageState)
  const [pendingOffer, setPendingOffer] = useRecoilState(pendingOfferState)
  const setAcceptedOffer = useSetRecoilState(acceptedOfferPostState)
  const setCalcPaymentFrequency = useSetRecoilState(calcPaymentFrequencyState)
  const setCommissionRate = useSetRecoilState(commissionRateState)
  const setLoanAmount = useSetRecoilState(loanAmountState)
  const setLoanSpecialist = useSetRecoilState(loanSpecialistState)
  const setLoanPaymentFrequencyOptions = useSetRecoilState(
    loanPaymentFrequencyOptionsState,
  )
  const setMerchantName = useSetRecoilState(merchantNameState)
  const setMonthMax = useSetRecoilState(monthMaxState)
  const setOfferType = useSetRecoilState(offerTypeState)
  const setRequirementList = useSetRecoilState(requirementListState)
  const setSelectedTier = useSetRecoilState(selectedTierState)
  const setTierList = useSetRecoilState(tierListState)

  const loadSavedCalc = useRecoilValue(loadSavedCalcState)
  const setIsLoading = useSetRecoilState(isPageLoadingState)

  // load pending offer
  useEffect(() => {
    console.log('calcUuid', calcUuid)

    if (calcUuid && !loadSavedCalc) {
      const getPendingOffer = async (uuid) => {
        setIsLoading(true)
        let response
        try {
          response = await partnerPortalApi.getPendingOffer(uuid)
        } catch (catchError) {
          console.error({ catchError })
          setDisplayPage(DisplayPageStatus.error)
        }
        if (
          !response ||
          typeof response != 'object' ||
          (hasOwn(response, 'responseCode') && response.responseCode === 404)
        ) {
          setDisplayPage(DisplayPageStatus.error)
          // error response
          console.error(
            'pending offer returned an error, TODO handle',
            response,
          )
        } else {
          // pendingoffer received:
          const logAcceptedOffer = response?.accepted_offer ? 'TRUE' : 'FALSE'
          logColor.yellow('STATUS: ' + response?.status)
          logColor.yellow('ACCEPTED OFFER: ' + logAcceptedOffer)
          logColor.blue('PENDING OFFER loaded', response)
          const pendingOffer = response as unknown as PendingOfferTemp

          // could audit the pending offer data here and show any errors
          // with button to continue if the data problem is not critical
          // could also generate Sentry errors here if data is bad.
          const pendingOfferErrors = getPendingOfferErrors(pendingOffer)
          if (pendingOfferErrors) {
            // errors in pending offer, TODO ability to bypass if not critical
            setDisplayComponent(
              <Loading>
                <div
                  style={{
                    backgroundColor: '#FFEEEE',
                    color: '#F00',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                  }}
                >
                  This is a temporary display for DEV usage it is not part of
                  the product
                </div>
                <div style={{ fontWeight: 'bold' }}>
                  ERRORS IN PendingOffer ...
                </div>
                {pendingOfferErrors.map((error, idx) => (
                  <div key={idx}>{error}</div>
                ))}
              </Loading>,
            )
          } else {
            setPendingOffer(pendingOffer)
          }
        }
        setIsLoading(false)
      }
      console.log({ calcUuid })
      getPendingOffer(calcUuid)
    }
  }, [
    calcUuid,
    setDisplayPage,
    loadSavedCalc,
    setErrorMessage,
    setPendingOffer,
    setIsLoading,
  ])

  // set pageStatus based on PendingOffer status
  const setPaymentFrequencyOption = useCallback(
    (frequency: PaymentFrequency) => {
      setCalcPaymentFrequency(frequency)
      const paymentFrequencyOptions = [PaymentFrequency.daily]
      if (frequency == PaymentFrequency.weekly) {
        paymentFrequencyOptions.push(PaymentFrequency.weekly)
      }
      setLoanPaymentFrequencyOptions(paymentFrequencyOptions)
    },
    [setCalcPaymentFrequency, setLoanPaymentFrequencyOptions],
  )

  useEffect(() => {
    if (!pendingOffer) return

    // when pending offer is loaded, update recoil: acceptedOffer, requirementList
    setAcceptedOffer(
      pendingOffer.accepted_offer ? { ...pendingOffer.accepted_offer } : null,
    )
    setRequirementList(
      pendingOffer.requirements_to_fund
        ? pendingOffer.requirements_to_fund?.split('\n')
        : null,
    )

    setMerchantName(pendingOffer.merchant_name)
    setLoanSpecialist(pendingOffer.owner)

    if (!loadSavedCalc) {
      setPaymentFrequencyOption(pendingOffer.frequency)

      const tierList = pendingOffer.pricing_tiers_list_v2
      setTierList(tierList)

      // if an accepted_offer exists, then that pricing tier is current
      const currentTier =
        pendingOffer.accepted_offer?.pricing_tier || pendingOffer.pricing_tier
      setSelectedTier(currentTier)
      setMonthMax(pendingOffer.term_months_max)
      setOfferType(pendingOffer.offer_type)
    }

    // pendingOffer updated, 2 update displayPageStatus
    // error responses are handled in 'load pending offer'
    switch (pendingOffer?.status) {
      case 'ACTIVE':
        setDisplayPage(DisplayPageStatus.landing)
        break
      case 'PROVISIONAL_ACCEPTED':
        setDisplayPage(DisplayPageStatus.selected)
        break
      case 'ACCEPTED_SELECT_DOC_DELIVERY_PREFERENCE':
        setDisplayPage(DisplayPageStatus.submitAgreement)
        break
      case 'COMPLETED':
        setDisplayPage(DisplayPageStatus.completed)
        break
      case 'INACTIVE':
        setDisplayPage(DisplayPageStatus.inactive)
        break
      default:
        setDisplayPage(DisplayPageStatus.loading)
    }
  }, [
    loadSavedCalc,
    pendingOffer,
    setAcceptedOffer,
    setDisplayPage,
    setRequirementList,
    setCommissionRate,
    setLoanAmount,
    setLoanPaymentFrequencyOptions,
    setLoanSpecialist,
    setMerchantName,
    setMonthMax,
    setOfferType,
    setSelectedTier,
    setTierList,
    setPaymentFrequencyOption,
  ])

  // change to error display if errorMessage is populated
  useEffect(() => {
    if (errorMessage) {
      setDisplayPage(DisplayPageStatus.error)
    }
    // else ??
  }, [errorMessage, setDisplayPage])

  // set the display component based on pageStatus
  useEffect(() => {
    console.log({ pageStatus: displayPage })
    switch (displayPage) {
      case DisplayPageStatus.landing:
        setDisplayComponent(<IsoCalculatorPage />)
        break
      case DisplayPageStatus.selected:
        setDisplayComponent(<OfferSelectedPage />)
        break
      case DisplayPageStatus.submitAgreement:
        setDisplayComponent(<CreateLoanAgreement />)
        break
      case DisplayPageStatus.completed:
        setDisplayComponent(
          <MessagePage type={MessagePageType.LOAN_AGREEMENT_CREATED} />,
        )
        break
      case DisplayPageStatus.error:
        setDisplayComponent(
          <MessagePage type={MessagePageType.SOMETHING_WENT_WRONG}>
            {errorMessage}
          </MessagePage>,
        )
        break
      case DisplayPageStatus.inactive:
        setDisplayComponent(<MessagePage type={MessagePageType.OFFER_LOCKED} />)
        break
      case DisplayPageStatus.loading:
        // this is the default component on load
        break
      default:
        setDisplayComponent(
          <div>
            call error page here, current error: unhandled page status:{' '}
            {displayPage}
          </div>,
        )
    }
  }, [displayPage, errorMessage])

  return <>{displayComponent}</>
}
